import React from 'react';
import styled from 'styled-components';

const ContactSection = styled.section`
    padding: 100px 20px;
    text-align: center;
    background: linear-gradient(135deg, #121212, #1f1f1f); /* Dark gradient background */
    color: #ffffff;
    position: relative;
    z-index: 1;
`;

const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 12px;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.5);
    z-index: 2;
`;

const ContactTitle = styled.h2`
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
`;

const ContactInfo = styled.div`
    margin: 20px 0;
    font-size: 18px;
    line-height: 1.6;
`;

const ContactLink = styled.a`
    color: #ff5722;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.3s, transform 0.3s;

    &:hover {
        color: #e64a19;
        transform: scale(1.05);
    }
`;

const Contact = () => {
    return (
        <ContactSection id="contact">
            <ContactContainer>
                <ContactTitle>Contact Us</ContactTitle>
                <ContactInfo>
                    <p>Email: <strong><a href="mailto:support@heloki.net" style={{ color: '#ffffff' }}>support@heloki.net</a></strong></p>
                    <p>Discord Invite: <strong><ContactLink href="https://discord.gg/heloki" target="_blank">discord.gg/heloki</ContactLink></strong></p>
                </ContactInfo>
            </ContactContainer>
        </ContactSection>
    );
};

export default Contact;
